body {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner {
    background-color: rgba(0,0,0,0.05);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 2px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0,0,0,0.3);
  }
  // FIREFOX STYLES
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.3) rgba(0,0,0,0.05);
  }
}

// DARK THEME

.dark-theme {
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-corner  {
    background-color: rgba(0,0,0,0.15);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.35);
  }
  // FIREFOX STYLES
  * {
    scrollbar-color: rgba(255,255,255,0.35) rgba(0,0,0,0.12);
  }
}

