// THESE ARE GLOBAL MATERIAL Settings
@import './design_system';

html {
  // NOTE for some reason SCSS vars $var-name do not work with plain CSS vars --var_name so use proper values.

  // TOOLTIPS
  --mdc-plain-tooltip-container-color: #585759;
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-tooltip-word-break: normal;

  // SELECT LIST
  --mat-select-panel-background-color: #fff;
  --mat-option-hover-state-layer-color:#F0F0F0;

  .cdk-overlay-pane.navigation-tabs{
    min-width: max-content;
    --mat-option-label-text-size: 12px;
    --mat-option-label-text-line-height:30px;

    mat-option {
      min-height: 30px;
    }

    .mat-mdc-option .mat-pseudo-checkbox-minimal {
      color: #fff;
    }
  }

  // Selected State
  --mat-option-selected-state-layer-color: #57B947;
  --mat-option-selected-state-label-text-color: #fff;
}

mat-tooltip-component {
  .mdc-tooltip {
    white-space: pre-line;

    .mdc-tooltip__surface {
      max-width: 420px;
    }
  }
}

.mat-mdc-menu-item {
  min-height: 24px !important;
}

.mat-mdc-select-panel {
  padding: 0 !important;
}
