form {
  label {
    display: block;
    margin-bottom: .5rem;
  }

  .error {
    display: none;
  }

  &.ng-dirty, &.ng-submitted {
    .ng-invalid {
      border-left: 5px solid #a94442; /* red */
    }

    .error {
      display: block;
    }
  }

  .ng-valid[required], .ng-valid.required {
    border-left: 5px solid #42A948; /* green */
  }
}
