/** verified Enverus colors per style suggestions images **/
$env_neutral_2: #dddedf;  /** what we were using **/

$env_neutral_1: #f3f6f7;
$env_neutral_3: #a7a6aa;
$env_neutral_4: #808083;
$env_neutral_5: #585759;

$brand-eir-org: #f78d2d;
$brand-colors-primary-green: #57b947;

/**** Colors ****/
$accent-colors-blue: #247c96;
$accent-colors-blue-dark: #1a5a6d;
$accent-colors-blue-light: #5abbd7;
$accent-colors-orange: #f79649;
$accent-colors-orange-dark: #d64000;
$accent-colors-orange-light: #fabc8a;
$accent-colors-purple: #c79bf2;
$accent-colors-purple-dark: #7d3cbd;
$accent-colors-purple-light: #ead9fa;
$brand-colors-primary-dark: #3c8321;
$brand-colors-primary-light: #a0ef6e;
$context-colors-danger: #ff5444;
$context-colors-danger-dark: #dd301d;
$context-colors-danger-light: #ffd4d1;
$context-colors-info: #329dff;
$context-colors-info-dark: #006cff;
$context-colors-info-light: #9ed1ff;
$context-colors-success: #56bc2f;
$context-colors-success-dark: #3c8321;
$context-colors-success-light: #dff1d8;
$context-colors-warning: #ffba00;
$context-colors-warning-dark: #986f00;
$context-colors-warning-light: #ffeec2;
$neutrals-black: #121212;
$neutrals-gray-1: #1c1b1d;
$neutrals-gray-10: #b2b1b3;
$neutrals-gray-11: #cbcdce;
$neutrals-gray-12: #ddddde;
$neutrals-gray-13: #e7e7e7;
$neutrals-gray-14: #f0f0f0;
$neutrals-gray-15: #fafafa;
$neutrals-gray-2: #29272b;
$neutrals-gray-3: #323035;
$neutrals-gray-4: #3c3a3f;
$neutrals-gray-5: #48454b;
$neutrals-gray-6: #56535b;
$neutrals-gray-7: #625f66;
$neutrals-gray-8: #a6a6a6;
$neutrals-gray-9: #989a9c;
$neutrals-white: #fdfdfd;
$transparencies-black-10: rgba(0, 0, 0, 0.1);
$transparencies-black-20: rgba(0, 0, 0, 0.2);
$transparencies-black-30: rgba(0, 0, 0, 0.3);
$transparencies-black-40: rgba(0, 0, 0, 0.4);
$transparencies-black-50: rgba(0, 0, 0, 0.5);
$transparencies-black-60: rgba(0, 0, 0, 0.6);
$transparencies-black-70: rgba(0, 0, 0, 0.7);
$transparencies-transparent: rgba(255, 255, 255, 0.0);
$transparencies-white-20: rgba(255, 255, 255, 0.2);
$transparencies-white-30: rgba(255, 255, 255, 0.3);
$transparencies-white-40: rgba(255, 255, 255, 0.4);
$transparencies-white-50: rgba(255, 255, 255, 0.5);
$transparencies-white-60: rgba(255, 255, 255, 0.6);
$transparencies-white-70: rgba(255, 255, 255, 0.7);
$visualizations-sequential-blue-color-1: #f7fbff;
$visualizations-sequential-blue-color-2: #deebf7;
$visualizations-sequential-blue-color-3: #c6dbef;
$visualizations-sequential-blue-color-4: #9ecae1;
$visualizations-sequential-blue-color-5: #6baed6;
$visualizations-sequential-blue-color-6: #4292c6;
$visualizations-sequential-blue-color-7: #2171b5;
$visualizations-sequential-blue-color-8: #08519c;
$visualizations-sequential-blue-color-9: #08306b;
$visualizations-sequential-ylgnbu-color-1: #ffffd9;
$visualizations-sequential-ylgnbu-color-2: #edf8b1;
$visualizations-sequential-ylgnbu-color-3: #c7e9b4;
$visualizations-sequential-ylgnbu-color-4: #7fcdbb;
$visualizations-sequential-ylgnbu-color-5: #41b6c4;
$visualizations-sequential-ylgnbu-color-6: #1d91c0;
$visualizations-sequential-ylgnbu-color-7: #225ea8;
$visualizations-sequential-ylgnbu-color-8: #253494;
$visualizations-sequential-ylgnbu-color-9: #081d58;
$visualizations-d3-schemecategory10-color-1: #1f77b4;
$visualizations-d3-schemecategory10-color-10: #17becf;
$visualizations-d3-schemecategory10-color-2: #ff7f0e;
$visualizations-d3-schemecategory10-color-3: #2ca02c;
$visualizations-d3-schemecategory10-color-4: #d62728;
$visualizations-d3-schemecategory10-color-5: #9467bd;
$visualizations-d3-schemecategory10-color-6: #8c564b;
$visualizations-d3-schemecategory10-color-7: #e377c2;
$visualizations-d3-schemecategory10-color-8: #7f7f7f;
$visualizations-d3-schemecategory10-color-9: #bcbd22;
$visualizations-d3-schemecategory20-color-1: #1f77b4;
$visualizations-d3-schemecategory20-color-10: #c4afd5;
$visualizations-d3-schemecategory20-color-11: #a8675a;
$visualizations-d3-schemecategory20-color-12: #c39b92;
$visualizations-d3-schemecategory20-color-13: #e378c3;
$visualizations-d3-schemecategory20-color-14: #f7b6d2;
$visualizations-d3-schemecategory20-color-15: #808080;
$visualizations-d3-schemecategory20-color-16: #c7c7c7;
$visualizations-d3-schemecategory20-color-17: #bfbf22;
$visualizations-d3-schemecategory20-color-18: #dcdc8f;
$visualizations-d3-schemecategory20-color-19: #17bccf;
$visualizations-d3-schemecategory20-color-2: #aec7e8;
$visualizations-d3-schemecategory20-color-20: #afc8e9;
$visualizations-d3-schemecategory20-color-3: #ff7f0e;
$visualizations-d3-schemecategory20-color-4: #ffbb78;
$visualizations-d3-schemecategory20-color-5: #2ca02c;
$visualizations-d3-schemecategory20-color-6: #99df8b;
$visualizations-d3-schemecategory20-color-7: #d72828;
$visualizations-d3-schemecategory20-color-8: #ff9694;
$visualizations-d3-schemecategory20-color-9: #9367bc;
$visualizations-d3-schemecategory20c-color-1: #3182bd;
$visualizations-d3-schemecategory20c-color-10: #74c476;
$visualizations-d3-schemecategory20c-color-11: #a1d99b;
$visualizations-d3-schemecategory20c-color-12: #c7e9c0;
$visualizations-d3-schemecategory20c-color-13: #756bb1;
$visualizations-d3-schemecategory20c-color-14: #9e9ac8;
$visualizations-d3-schemecategory20c-color-15: #bcbddc;
$visualizations-d3-schemecategory20c-color-16: #dadaeb;
$visualizations-d3-schemecategory20c-color-17: #636363;
$visualizations-d3-schemecategory20c-color-18: #969696;
$visualizations-d3-schemecategory20c-color-19: #bdbdbd;
$visualizations-d3-schemecategory20c-color-2: #6baed6;
$visualizations-d3-schemecategory20c-color-20: #d9d9d9;
$visualizations-d3-schemecategory20c-color-3: #9ecae1;
$visualizations-d3-schemecategory20c-color-4: #c6dbef;
$visualizations-d3-schemecategory20c-color-5: #e6550d;
$visualizations-d3-schemecategory20c-color-6: #fd8d3c;
$visualizations-d3-schemecategory20c-color-7: #fdae6b;
$visualizations-d3-schemecategory20c-color-8: #fdd0a2;
$visualizations-d3-schemecategory20c-color-9: #31a354;
$visualizations-d3-schemepaired-color-1: #a6cee3;
$visualizations-d3-schemepaired-color-10: #6a3d9a;
$visualizations-d3-schemepaired-color-11: #ffff99;
$visualizations-d3-schemepaired-color-12: #b15928;
$visualizations-d3-schemepaired-color-2: #1f78b4;
$visualizations-d3-schemepaired-color-3: #b2df8a;
$visualizations-d3-schemepaired-color-4: #33a02c;
$visualizations-d3-schemepaired-color-5: #fb9a99;
$visualizations-d3-schemepaired-color-6: #e31a1c;
$visualizations-d3-schemepaired-color-7: #fdbf6f;
$visualizations-d3-schemepaired-color-8: #ff7f00;
$visualizations-d3-schemepaired-color-9: #cab2d6;
$visualizations-d3-schemepastel-color-1: #fbb4ae;
$visualizations-d3-schemepastel-color-2: #b3cde3;
$visualizations-d3-schemepastel-color-3: #ccebc5;
$visualizations-d3-schemepastel-color-4: #decbe4;
$visualizations-d3-schemepastel-color-5: #fed9a6;
$visualizations-d3-schemepastel-color-6: #e5d8bd;
$visualizations-d3-schemepastel-color-7: #fddaec;
$visualizations-d3-schemepastel-color-8: #f2f2f2;

/**** Fonts ****/
$default-font: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Ubuntu', 'Cantarell', 'Helvetica Neue', 'Helvetica', sans-serif;

/** heading-large **/
$heading-large-font-family: $default-font;
$heading-large-font-size: 36px;
$heading-large-letter-spacing: 0px;
$heading-large-line-height: 44px;
$heading-large-font-weight: 500;
$heading-large-color: inherit;

@mixin heading-large() {
  font-family: $heading-large-font-family;
  font-size: $heading-large-font-size;
  letter-spacing: $heading-large-letter-spacing;
  line-height: $heading-large-line-height;
  font-weight: $heading-large-font-weight;
  color: $heading-large-color;
}
/** heading-medium **/
$heading-medium-font-family: $default-font;
$heading-medium-font-size: 28px;
$heading-medium-letter-spacing: 0px;
$heading-medium-line-height: 36px;
$heading-medium-font-weight: 500;
$heading-medium-color: inherit;

@mixin heading-medium() {
  font-family: $heading-medium-font-family;
  font-size: $heading-medium-font-size;
  letter-spacing: $heading-medium-letter-spacing;
  line-height: $heading-medium-line-height;
  font-weight: $heading-medium-font-weight;
  color: $heading-medium-color;
}
/** heading-small **/
$heading-small-font-family: $default-font;
$heading-small-font-size: 20px;
$heading-small-letter-spacing: 0px;
$heading-small-line-height: 28px;
$heading-small-font-weight: 500;
$heading-small-color: inherit;

@mixin heading-small() {
  font-family: $heading-small-font-family;
  font-size: $heading-small-font-size;
  letter-spacing: $heading-small-letter-spacing;
  line-height: $heading-small-line-height;
  font-weight: $heading-small-font-weight;
  color: $heading-small-color;
}
/** text-default-link **/
$text-default-link-font-family: 'Roboto', 'Roboto-Regular';
$text-default-link-font-size: 14px;
$text-default-link-letter-spacing: 0px;
$text-default-link-line-height: 20px;
$text-default-link-font-weight: 400;
$text-default-link-text-decoration: underline;
$text-default-link-color: inherit;

@mixin text-default-link() {
  font-family: $text-default-link-font-family;
  font-size: $text-default-link-font-size;
  letter-spacing: $text-default-link-letter-spacing;
  line-height: $text-default-link-line-height;
  font-weight: $text-default-link-font-weight;
  text-decoration: $text-default-link-text-decoration;
  color: $text-default-link-color;
}
/** text-default-weight-medium **/
$text-default-weight-medium-font-family: $default-font;
$text-default-weight-medium-font-size: 14px;
$text-default-weight-medium-letter-spacing: 0px;
$text-default-weight-medium-line-height: 20px;
$text-default-weight-medium-font-weight: 500;
$text-default-weight-medium-color: inherit;

@mixin text-default-weight-medium() {
  font-family: $text-default-weight-medium-font-family;
  font-size: $text-default-weight-medium-font-size;
  letter-spacing: $text-default-weight-medium-letter-spacing;
  line-height: $text-default-weight-medium-line-height;
  font-weight: $text-default-weight-medium-font-weight;
  color: $text-default-weight-medium-color;
}
/** text-default-weight-regular **/
$text-default-weight-regular-font-family: 'Roboto', 'Roboto-Regular';
$text-default-weight-regular-font-size: 14px;
$text-default-weight-regular-letter-spacing: 0px;
$text-default-weight-regular-line-height: 20px;
$text-default-weight-regular-font-weight: 400;
$text-default-weight-regular-color: inherit;

@mixin text-default-weight-regular() {
  font-family: $text-default-weight-regular-font-family;
  font-size: $text-default-weight-regular-font-size;
  letter-spacing: $text-default-weight-regular-letter-spacing;
  line-height: $text-default-weight-regular-line-height;
  font-weight: $text-default-weight-regular-font-weight;
  color: $text-default-weight-regular-color;
}
/** text-large-weight-medium **/
$text-large-weight-medium-font-family: $default-font;
$text-large-weight-medium-font-size: 16px;
$text-large-weight-medium-letter-spacing: 0px;
$text-large-weight-medium-line-height: 24px;
$text-large-weight-medium-font-weight: 500;
$text-large-weight-medium-color: inherit;

@mixin text-large-weight-medium() {
  font-family: $text-large-weight-medium-font-family;
  font-size: $text-large-weight-medium-font-size;
  letter-spacing: $text-large-weight-medium-letter-spacing;
  line-height: $text-large-weight-medium-line-height;
  font-weight: $text-large-weight-medium-font-weight;
  color: $text-large-weight-medium-color;
}
/** text-large-weight-regular **/
$text-large-weight-regular-font-family: 'Roboto', 'Roboto-Regular';
$text-large-weight-regular-font-size: 16px;
$text-large-weight-regular-letter-spacing: 0px;
$text-large-weight-regular-line-height: 24px;
$text-large-weight-regular-font-weight: 400;
$text-large-weight-regular-color: inherit;

@mixin text-large-weight-regular() {
  font-family: $text-large-weight-regular-font-family;
  font-size: $text-large-weight-regular-font-size;
  letter-spacing: $text-large-weight-regular-letter-spacing;
  line-height: $text-large-weight-regular-line-height;
  font-weight: $text-large-weight-regular-font-weight;
  color: $text-large-weight-regular-color;
}
/** text-small-link **/
$text-small-link-font-family: $default-font;
$text-small-link-font-size: 12px;
$text-small-link-letter-spacing: 0px;
$text-small-link-line-height: 16px;
$text-small-link-font-weight: 500;
$text-small-link-text-decoration: underline;
$text-small-link-color: inherit;

@mixin text-small-link() {
  font-family: $text-small-link-font-family;
  font-size: $text-small-link-font-size;
  letter-spacing: $text-small-link-letter-spacing;
  line-height: $text-small-link-line-height;
  font-weight: $text-small-link-font-weight;
  text-decoration: $text-small-link-text-decoration;
  color: $text-small-link-color;
}
/** text-small-weight-medium **/
$text-small-weight-medium-font-family: $default-font;
$text-small-weight-medium-font-size: 12px;
$text-small-weight-medium-letter-spacing: 0px;
$text-small-weight-medium-line-height: 16px;
$text-small-weight-medium-font-weight: 500;
$text-small-weight-medium-color: inherit;

@mixin text-small-weight-medium() {
  font-family: $text-small-weight-medium-font-family;
  font-size: $text-small-weight-medium-font-size;
  letter-spacing: $text-small-weight-medium-letter-spacing;
  line-height: $text-small-weight-medium-line-height;
  font-weight: $text-small-weight-medium-font-weight;
  color: $text-small-weight-medium-color;
}
/** text-small-weight-regular **/
$text-small-weight-regular-font-family: 'Roboto', 'Roboto-Regular';
$text-small-weight-regular-font-size: 12px;
$text-small-weight-regular-letter-spacing: 0px;
$text-small-weight-regular-line-height: 16px;
$text-small-weight-regular-font-weight: 400;
$text-small-weight-regular-color: inherit;

@mixin text-small-weight-regular() {
  font-family: $text-small-weight-regular-font-family;
  font-size: $text-small-weight-regular-font-size;
  letter-spacing: $text-small-weight-regular-letter-spacing;
  line-height: $text-small-weight-regular-line-height;
  font-weight: $text-small-weight-regular-font-weight;
  color: $text-small-weight-regular-color;
}
